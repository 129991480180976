@import url("https://use.typekit.net/tya7uuw.css");

@font-face {
  font-family: "MXBrush";
  src: local("MXBrush"),
    url("fonts/MXBrush/MXBrush-Regular.otf") format("truetype");
}

body {
  margin: 0;
  font-family: Proxima-nova, "SF Pro Text", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
